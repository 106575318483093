<template>
  <section id="PageSingleSeason" class="col-12 wrapper-text">
    <EpisodeTV :tv="tv" :season="season"></EpisodeTV>
    <SeasonTv :tv="tv"></SeasonTv>
    <BioTv :tv="tv"></BioTv>
  </section>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageSingleSeason",
  components: {
    'BioTv': defineAsyncComponent(() =>
        import('@/components/section/BioTv')
    ),
    'SeasonTv': defineAsyncComponent(() =>
        import('@/components/section/SeasonTv')
    ),
    'EpisodeTV': defineAsyncComponent(() =>
        import('@/components/section/EpisodeTV')
    ),
  },
  watch: {
    "$route.params.season": function (id) {
      if (this.$route.params.season) {
        this.getSeason(this.$route.params.id, id);
      }
    },
  },
  computed: {
    tv() {
      return this.$store.state.getMovie.tv;
    },
    season() {
      return this.$store.state.getMovie.season;
    },
  },
  methods: {
    getTv(id) {
      let query = `/tv/${id}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setTv",
      });
    },
    getSeason(id, season) {
      let query = `/tv/${id}/season/${season}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setSeason",
      });
    },
  },
  mounted() {
    this.getTv(this.$route.params.id);
    this.getSeason(this.$route.params.id, this.$route.params.season);
  },
};
</script>

<style scoped></style>
